<template>
  <b-card
    no-body
    class="business-card"
  >
    <b-card-body>
      <cuentaForm
        :validarPassword="false"
        :configuracion="true"
        usuarioSubmit="Editar Cuenta"
        :usuario="userData"
        @processCuenta="editarPassword"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const cuentaForm = () => import('@/layouts/components/Navbar/Configuraciones/Cuenta/CuentaForm.vue')

export default {
  components: {
    BCard,
    BCardBody,
    cuentaForm,
  },
  data() {
    return {
      userData: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      getUsuarios: 'usuarios/getUsuarios',
    }),
  },
  watch: {
    getUsuarios() {
      this.userData = this.getUsuarios.find(u => u.id === this.user.id)
    },
  },
  mounted() {
    this.fetchUsuarios()
  },
  methods: {
    ...mapActions({
      fetchUsuarios: 'usuarios/fetchUsuarios',
      updatePasswordUsuario: 'usuarios/updatePasswordUsuario',
    }),
    ...mapMutations('usuarios', ['setUsuario']),
    editarPassword(usuario) {
      this.updatePasswordUsuario(usuario).then(message => {
        if (message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Contraseña editada con éxito 👍',
              text: 'Su contraseña fue editada con éxito!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'home',
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
